import Scroll from "./library/scroll";

require('../scss/style.scss');

const mainNav = document.getElementById('main-nav') as HTMLElement | undefined;
const mainNavToggle = document.getElementById('main-nav-toggle') as HTMLElement | undefined;
const scrollTopElement = document.getElementById('scroll-top');

if (mainNav && mainNavToggle) {
    mainNavToggle.addEventListener('click', () => mainNav.classList.toggle('active'));
}

if (scrollTopElement) {
    scrollTopElement.addEventListener('click', () => Scroll.to(0, 200));
    Scroll.add(function(position: number) {
        scrollTopElement.classList[position > 0 ? 'add' : 'remove']('show');
    });
}
