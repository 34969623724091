export default class Scroll {
    private static readonly requestAnimationFrame = (
        (window as any).requestAnimationFrame ||
        (window as any).webkitRequestAnimationFrame ||
        (window as any).mozRequestAnimationFrame ||
        (window as any).msRequestAnimationFrame ||
        (window as any).oRequestAnimationFrame
    ).bind(window);

    private static callbacks: Function[] = [];
    private static instance: Scroll;
    private static position = Scroll.getPosition();

    constructor() {
        if (!Scroll.instance) {
            Scroll.instance = this;

            Scroll.callback();
        }
    }

    public static add(callback: Function) {
        return Scroll.callbacks.push(callback);
    }

    public static remove(callbackOrIndex: number | Function) {
        try {
            if ('function' === typeof callbackOrIndex) {
                let callbackIndex = Scroll.callbacks.indexOf(callbackOrIndex);
                while (callbackIndex >= 0) {
                    Scroll.callbacks.splice(callbackIndex, 1);
                    callbackIndex = Scroll.callbacks.indexOf(callbackOrIndex);
                }
            } else {
                Scroll.callbacks.splice(callbackOrIndex, 1);
            }
        } catch (err) {}
    }

    public static clear() {
        Scroll.callbacks = [];
    }

    private static callback() {
        const position = Scroll.getPosition();

        if (position !== Scroll.position) {
            const direction = position > Scroll.position ? 'down' : 'up';
            let removeFailedCallbacks: number[] = [];

            Scroll.callbacks.forEach((callback, index) => {
                try {
                    callback(position, direction);
                } catch (e) {
                    removeFailedCallbacks.push(index);
                    console.warn(e);
                }
            });

            removeFailedCallbacks.sort((a, b) => b - a).forEach(i => Scroll.callbacks.splice(i, 1));
            removeFailedCallbacks = [];
            Scroll.position = Scroll.getPosition();
        }

        Scroll.requestAnimationFrame(Scroll.callback);
    }

    public static getPosition() {
        return window.pageYOffset || document.documentElement
            ? document.documentElement.scrollTop || document.body.scrollTop
            : document.body.scrollTop;
    }

    public static to(
        destination: HTMLElement | number,
        duration = 200,
        easing = 'linear',
        callback?: Function,
    ) {
        const easings: { [key: string]: Function } = {
            linear(t: number) {
                return t;
            },
            easeInQuad(t: number) {
                return t * t;
            },
            easeOutQuad(t: number) {
                return t * (2 - t);
            },
            easeInOutQuad(t: number) {
                return t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t;
            },
            easeInCubic(t: number) {
                return t * t * t;
            },
            easeOutCubic(t: number) {
                return --t * t * t + 1;
            },
            easeInOutCubic(t: number) {
                return t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1;
            },
            easeInQuart(t: number) {
                return t * t * t * t;
            },
            easeOutQuart(t: number) {
                return 1 - --t * t * t * t;
            },
            easeInOutQuart(t: number) {
                return t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t;
            },
            easeInQuint(t: number) {
                return t * t * t * t * t;
            },
            easeOutQuint(t: number) {
                return 1 + --t * t * t * t * t;
            },
            easeInOutQuint(t: number) {
                return t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t;
            },
        };

        const start = window.pageYOffset;
        const startTime = 'now' in window.performance ? performance.now() : new Date().getTime();

        const documentHeight = Math.max(
            document.body.scrollHeight,
            document.body.offsetHeight,
            document.documentElement.clientHeight,
            document.documentElement.scrollHeight,
            document.documentElement.offsetHeight,
        );
        const windowHeight =
            window.innerHeight ||
            document.documentElement.clientHeight ||
            document.getElementsByTagName('body')[0].clientHeight;
        const destinationOffset =
            typeof destination === 'number' ? destination : destination.offsetTop;
        const destinationOffsetToScroll = Math.round(
            documentHeight - destinationOffset < windowHeight
                ? documentHeight - windowHeight
                : destinationOffset,
        );

        if ('requestAnimationFrame' in window === false) {
            window.scroll(0, destinationOffsetToScroll);
            if (callback) {
                callback();
            }
            return;
        }

        function scroll() {
            const now = 'now' in window.performance ? performance.now() : new Date().getTime();
            const time = Math.min(1, (now - startTime) / duration);
            const timeFunction = easings[easing](time);

            window.scroll(0, Math.ceil(timeFunction * (destinationOffsetToScroll - start) + start));

            if (
                Math.ceil(window.pageYOffset) === destinationOffsetToScroll ||
                Math.ceil(window.pageYOffset) === destinationOffsetToScroll - 1 ||
                Math.ceil(window.pageYOffset) === destinationOffsetToScroll + 1
            ) {
                if (callback) {
                    callback();
                }
                return;
            }

            requestAnimationFrame(scroll);
        }

        scroll();
    }
}

export const scroll = new Scroll();
